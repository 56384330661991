import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";
import image1 from './../../../assets/image/rebrand/svg/days-30.svg';
import image2 from './../../../assets/image/rebrand/svg/price-rises.svg';
import image3 from './../../../assets/image/rebrand/svg/existing-contract.svg';
import image4 from './../../../assets/image/rebrand/svg/price-match.svg';
import image5 from './../../../assets/image/rebrand/svg/minimum-speed.svg';
import image6 from './../../../assets/image/rebrand/svg/icon-customer-service.svg';
import image8 from './../../../assets/image/rebrand/png/youfibre_o.png'
import premiseLine from './../../../assets/image/rebrand/png/premise-line.png'
import promiseTitle from './../../../assets/image/rebrand/png/promise-title.png'
import promiseTitleMobile from './../../../assets/image/rebrand/png/promise-title-mobile.png'
import divider from './../../../assets/image/rebrand/png/ultrafast-internet.png'

import TextFit from "../TextFit/TextFit";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import TiltDivider from "../../../sections/TiltDivider";

const StyledDivider = styled.div`
    margin-top: 35px;
    background-color: #04c78d;
`

const ChooseYou = (props) => {

    const responsive = {
        0: { items: 2 },
        568: { items: 3 },
        1024: { items: 6 },
    };

    const responsive1 = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 6 },
    };

    const items = [
        <div className="promise-wrapper text-center">
            <img src={image1} className="mb-3" width={90}/>
            <h4>30 day<br/>MONEY BACK<br/>GUARANTEE</h4>
        </div>,
        <div className="promise-wrapper text-center">
            <img src={image2} className="mb-3" width={90}/>
            <h4>NO<br/>in-contract<br/>price rises</h4>
        </div>,
        <div className="promise-wrapper text-center">
            <img src={image3} className="mb-3" width={90}/>
            <h4>EXISTING<br/>CONTRACT<br/>BUY-OUT</h4>
        </div>,
        <div className="promise-wrapper text-center">
            <img src={image4} className="mb-3" width={90}/>
            <h4>PRICE<br/>MATCH<br/>COMMITMENT</h4>
        </div>,
        <div className="promise-wrapper text-center">
            <img src={image5} className="mb-3" width={90}/>
            <h4>Minimum<br/>speed<br/>guarantee</h4>
        </div>,
        <div className="promise-wrapper text-center">
            <img src={image6} className="mb-3" width={90}/>
            <h4>24/7<br/>Customer<br/>Service</h4>
        </div>,
      ];

    return (
        <>
            <Section className="dark-green-bg">
                <Container>
                    <Row className="mb-5">
                        <Col sm={12} className="text-center d-none d-lg-block">
                            <img src={promiseTitle} className="w-100"/>
                        </Col>
                        <Col sm={12} className="text-center d-md-block d-lg-none">
                            <img src={promiseTitleMobile} className="w-100"/>
                        </Col>
                    </Row>
                    <Row>
                        <AliceCarousel
                            mouseTracking
                            responsive={responsive1}
                            disableButtonsControls={true}
                            infinite={true}
                            items={items}
                            controlsStrategy="default"
                            />
                    </Row>
                </Container>
            </Section>
            <StyledDivider>
                <TiltDivider rotate={2} img={divider}/>
            </StyledDivider>
        </>
    )
};

export default ChooseYou;
